import React from "react"
import {
  HStack,
  Box,
  Stack,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { MdPermPhoneMsg } from "react-icons/md"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Meta from "../../components/Meta"

const About = () => {
  return (
    <Layout>
      <Meta
        title="About Bullseye Lawn Care"
        description="Learn about the history of Bullseye Lawn Care. We are lawn care experts serving the Sioux Falls area."
      />
      <Box as="section">
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          minH="800px"
          mx="auto"
          px={{ base: "6", md: "8" }}
          py="20"
        >
          <Flex
            align="center"
            justify="center"
            direction={{ base: "column-reverse", md: "row" }}
          >
            <Box flex="1" maxW="440px">
              <Heading
                as="h1"
                size="2xl"
                fontWeight="extrabold"
                color={useColorModeValue(
                  "bullseyeGreen.600",
                  "bullseyeGreen.300"
                )}
              >
                About Bullseye Lawn Care
              </Heading>
              <Text
                fontSize="lg"
                fontWeight="medium"
                color={useColorModeValue("gray.600", "inherit")}
                mt="6"
              >
                Hey there, I'm Skylar and I started Bullseye Lawn Care to
                provide professional lawn care and property maintenance services
                to the Sioux Falls area. I have over a decade of experience in
                the industry and look forward to getting to know you and your
                property.
              </Text>
              <Text
                fontSize="lg"
                fontWeight="medium"
                color={useColorModeValue("gray.600", "inherit")}
                mt="6"
              >
                I've spent years learning how to get lawns to achieve their full
                potential. If you are interested in talking about how I might be
                able to help you with your property, please give me a call!
              </Text>
              <Stack
                mt={{ base: "8", md: "16" }}
                spacing="4"
                fontWeight="extrabold"
              >
                <HStack
                  spacing="5"
                  color={useColorModeValue(
                    "bullseyeOrange.500",
                    "bullseyeOrange.300"
                  )}
                >
                  <Box fontSize="3xl" as={MdPermPhoneMsg} />
                  <Text fontSize={{ base: "xl", md: "2xl" }}>605-204-5458</Text>
                </HStack>
              </Stack>
            </Box>

            <Box
              aria-hidden
              className="spacer"
              flexShrink={0}
              boxSize={{ base: "10", xl: "20" }}
            />

            <Box flex="1" maxW="560px" h={{ base: "400px", md: "460px" }}>
              <StaticImage
                src="../../images/skylar.jpg"
                alt="Skylar Bullseye Lawn Care"
                placeholder="blurred"
                aspectRatio={0.8}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Layout>
  )
}

export default About
